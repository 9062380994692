import { useQuery } from 'global/utils';
import { JOB_FOLDER_APPROVED, JOB_FOLDER_FILLED } from 'talent-hub/constants';
import { useCustomerAuthorizedUserSession } from 'talent-hub/utils';
import type { SelectManageCandidatesQuery, SelectManageCandidatesQueryVariables } from './data';
import { SelectManageCandidates } from './data';
import { ManageCandidates } from './ManageCandidates';
import { serializeManageCandidates } from './ManageCandidates.serializer';

export function ManageCandidatesController() {
  const {
    isClientReviewer,
    isRecruiter,
    user,
    viewingOrganization,
    isClientProspect,
    userPrioritizedRole,
  } = useCustomerAuthorizedUserSession();

  const { loading, data } = useQuery<
    SelectManageCandidatesQuery,
    SelectManageCandidatesQueryVariables
  >(SelectManageCandidates, {
    variables: {
      organization: viewingOrganization.ID,
      user_id: user.id,
      job_folder_approved: JOB_FOLDER_APPROVED,
      job_folder_filled: JOB_FOLDER_FILLED,
    },
    fetchPolicy: 'network-only',
  });

  const {
    candidatesRequiringAction,
    candidatesReadyForReviewTitle,
    candidatesWithInterviewFeedbackTitle,
    hasOpenRoles,
  } = serializeManageCandidates(data, userPrioritizedRole);

  return (
    <ManageCandidates
      pageLayoutProps={{
        isClientReviewer,
        isRecruiter,
        orgName: viewingOrganization.name,
        user,
        isClientProspect,
        userPrioritizedRole,
      }}
      candidatesRequiringAction={candidatesRequiringAction}
      candidatesReadyForReviewTitle={candidatesReadyForReviewTitle}
      candidatesWithInterviewFeedbackTitle={candidatesWithInterviewFeedbackTitle}
      hasOpenRoles={hasOpenRoles}
      loading={loading}
      isClientReviewer={isClientReviewer}
    />
  );
}
