import { useEffect } from 'react';
import { NetworkStatus, useMutation } from '@apollo/client';
import { Switch, Route, useParams, useHistory, useLocation } from 'react-router-dom';

import * as events from 'global/events';
import { useQuery } from 'global/utils';
import { CustomRoute } from 'global/components';

import { useCustomerAuthorizedUserSession, useTalentHubFlags } from 'talent-hub/utils';
import { to_matching_candidates } from 'talent-hub/shared/features';

import { ScrollToTopOnPathChange } from 'talent-hub/components';
import type {
  SelectAiCandidatesMatchingJobMutation,
  SelectAiCandidatesMatchingJobMutationVariables,
} from 'talent-hub/role/client/new.dashboard/dashboard-roles/data';
import { SelectAICandidatesMatchingJob } from 'talent-hub/role/client/new.dashboard/dashboard-roles/data';
import { SelectRoleData } from './data';
import type { RoleDataQuery, RoleDataQueryVariables } from './data';

import { serializeRoleData } from './Role.serializer';

import type { RoleLayout } from './components/RoleLayout';
import { roleTabs } from './components/RoleLayout';
import { CandidateProfileController } from './subfeatures/candidate-profile';

import { CandidateProfileDrawer, Overview } from './subfeatures/progress';
import { JobRequirements } from './subfeatures/requirements';
import { RoleWeeklyReports } from './subfeatures/weekly-summaries';
import { JobCandidatesBuckets } from './subfeatures/job-candidates-buckets';
import { CreateEditJobController } from './subfeatures/create-edit-job';
import { ViewJobController } from './subfeatures/view-job';

import { CandidateFeedbackController } from './subfeatures/candidate-feedback';
import { ManageRolesController } from './subfeatures/manage-roles';
import { useInterviewFeedbackUtils } from './useInterviewFeedbackUtils';
import { ManageRolesDashboardController } from './subfeatures/manage-roles-roles-base';
import { serializeJobRequirements } from './subfeatures/requirements/JobRequirements.serializer';
import type { ProfileInfo } from './Role.types';

export function ActiveRoleController({
  children,
}: {
  children: ({
    activeRole,
    hiredCompleteCandidates,
    interviewFeedbackUtils,
    interviewsToBeScheduledCandidates,
    isQueryLoading,
    milestones,
    pastInterviewCandidates,
    pastOffersCandidates,
    pastSubmissionCandidates,
    recentCandidatesActivities,
    roleLayoutProps,
    upcomingInterviewCandidates,
    upcomingOffersCandidates,
    upcomingSubmissionCandidates,
    weeklySummaries,
    widgets,
    ai_extra_matches: {
      current_role_candidate_matches,
      is_loading_current_role_candidate_matches,
      view_all_candidate_matches_link,
    },
  }: {
    activeRole: string;
    // TODO: type this
    hiredCompleteCandidates: any;
    interviewFeedbackUtils: {
      isLoading: boolean;
      users: { value: string; label: string }[];
      handleOnSubmitInviteFeedback: (currentValues: {
        users: {
          value: string;
          label: string;
        }[];
        isRoleAccess: boolean;
      }) => { error: boolean };
    };
    // TODO: type this
    interviewsToBeScheduledCandidates: any;
    isQueryLoading: boolean;
    job: {
      employment_type: string | null;
      additionalInfo: string | null;
      companySize: string | null;
      experiences: string[];
      headcount: string;
      location: string;
      niceToHaveSkills: string | null;
      requiredSkills: string[];
      roleDescription: string | null;
      seniority: string;
      title: string | null;
      whatYouBring: string | null;
      whatYouWillDo: string | null;
    } | null;
    milestones: {
      milestone1?: {
        completed: boolean;
        date: string;
        datePrefix: string;
      };
      milestone2?: {
        completed: boolean;
        date: string;
        datePrefix: string;
      };
      milestone3?: {
        completed: boolean;
        date: string;
        datePrefix: string;
      };
      milestone4?: {
        completed: boolean;
        date: string;
        datePrefix: string;
      };
      milestone5?: {
        completed: boolean;
        date: string;
        datePrefix: string;
      };
    };
    // TODO: type this
    pastInterviewCandidates: any;
    // TODO: type this
    pastOffersCandidates: any;
    // TODO: type this
    pastSubmissionCandidates: any;
    recentCandidatesActivities: ProfileInfo[];
    roleLayoutProps: React.ComponentProps<typeof RoleLayout>;
    // TODO: type this
    upcomingInterviewCandidates: any;
    // TODO: type this
    upcomingOffersCandidates: any;
    // TODO: type this
    upcomingSubmissionCandidates: any;
    weeklySummaries: {
      dateRange: string;
      bucketSummaries: {
        title: string;
        week: { total: number; candidateNames: string };
        allTime: number;
      }[];
      candidatesActivityReviews: {
        id: number;
        name: string;
        activity: string;
        status: {
          date: string;
          title: string;
        };
      }[];
    }[];
    widgets: {
      totalSubmissions: number | null;
      totalActiveCandidates: number | null;
      totalPositions: number | null;
      totalHired: number | null;
    };
    ai_extra_matches: {
      current_role_candidate_matches: {
        id: number;
        firstName: string;
        lastName?: string;
        last_name_initial?: string;
        currentRole: string;
        yearsExperience: string;
        city?: string;
        country_choice?: {
          name: string;
        };
        skills: Array<{
          id: number | string;
          name: string;
          isSelected: boolean;
        }>;
        remainingSkills?: number;
      }[];
      is_loading_current_role_candidate_matches: boolean;
      view_all_candidate_matches_link: string;
    };
  }) => JSX.Element;
}) {
  const {
    userPrioritizedRole,
    isClientReviewer,
    isRecruiter,
    user,
    viewingOrganization,
    isClientProspect,
  } = useCustomerAuthorizedUserSession();
  const history = useHistory();
  const { state } = useLocation<{
    from?: string;
  }>();
  const { activeRole } = useParams<{
    activeRole: string;
  }>();

  const { loading, data, networkStatus } = useQuery<RoleDataQuery, RoleDataQueryVariables>(
    SelectRoleData,
    {
      variables: {
        organization: viewingOrganization.ID,
        job_id: +activeRole,
        applicant_workflow_offset: 0,
      },
      fetchPolicy: 'network-only',
      skip: !activeRole || isClientReviewer,
    },
  );
  const isQueryLoading = loading || networkStatus === NetworkStatus.refetch;

  const {
    roleTitle,
    buckets: {
      upcomingSubmissionCandidates,
      pastSubmissionCandidates,
      interviewsToBeScheduledCandidates,
      upcomingInterviewCandidates,
      pastInterviewCandidates,
      upcomingOffersCandidates,
      pastOffersCandidates,
      hiredCompleteCandidates,
      recentCandidatesActivities,
    },
    progress: {
      metrics: { totalSubmissions, totalActiveCandidates, totalPositions, totalHired },
      milestones,
    },
    weeklySummaries,
    ai_extra_matches,
  } = serializeRoleData(data);

  const job = serializeJobRequirements(data?.requirementsList);

  const [candidates_by_ai_matching_mutation, ai_query] = useMutation<
    SelectAiCandidatesMatchingJobMutation,
    SelectAiCandidatesMatchingJobMutationVariables
  >(SelectAICandidatesMatchingJob, {
    variables: ai_extra_matches.candidates_ai_matching_args!,
    context: {
      service: 'service',
    },
  });

  useEffect(() => {
    if (!ai_extra_matches.candidates_ai_matching_args || isClientReviewer) return;
    candidates_by_ai_matching_mutation();
    // We don't want to run this effect again irrespective of anything changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ai_extra_matches.candidates_ai_matching_args?.jobId]);

  const current_role_candidate_matches = to_matching_candidates(
    ai_query.data,
    ai_extra_matches.must_have_skills,
  );

  const handleBackAction = () => {
    if (state?.from === 'dashboard') {
      history.push(`/`);
      return;
    }
    if (isClientReviewer) {
      history.push(`/candidates`);
      return;
    }
    history.push(`/role`);
  };

  function backActionMessageMapper(from: string | undefined) {
    if (isClientReviewer) {
      return 'Back to Manage Candidates';
    }

    switch (from) {
      case 'dashboard':
        return 'Back to Dashboard';
      default:
        return 'Back to Manage Roles';
    }
  }

  const backActionMessage = backActionMessageMapper(state?.from);

  const interviewFeedbackUtils = useInterviewFeedbackUtils({
    applicantWorkflowID: undefined,
    jobID: data?.icims_job?.[0]?.job?.id!,
    shouldRefreshFeedbackList: true,
  });

  // Event tracking for new role
  useEffect(() => {
    if (!roleTitle || loading) return;
    events.track(events.name.viewedRole, {
      role: roleTitle,
    });
  }, [roleTitle, loading]);

  return children({
    activeRole,
    roleLayoutProps: {
      hiredCompleteCandidates: hiredCompleteCandidates.length,
      pageLayoutProps: {
        isClientReviewer,
        isRecruiter,
        orgName: viewingOrganization.name,
        user,
        isClientProspect,
        userPrioritizedRole,
        subHeader: null,
      },
      roleTitle,
      upcomingInterviewCandidates: upcomingInterviewCandidates.length,
      upcomingOffersCandidates: upcomingOffersCandidates.length,
      upcomingSubmissionCandidates: upcomingSubmissionCandidates.length,
      showSubHeader: false,
      handleBackAction,
      backActionMessage,
    },
    isQueryLoading,
    upcomingSubmissionCandidates,
    pastSubmissionCandidates,
    interviewsToBeScheduledCandidates,
    upcomingInterviewCandidates,
    pastInterviewCandidates,
    upcomingOffersCandidates,
    pastOffersCandidates,
    hiredCompleteCandidates,
    widgets: {
      totalSubmissions,
      totalActiveCandidates,
      totalPositions,
      totalHired,
    },
    milestones,
    recentCandidatesActivities,
    weeklySummaries,
    interviewFeedbackUtils,
    job,
    ai_extra_matches: {
      current_role_candidate_matches,
      is_loading_current_role_candidate_matches: ai_query.loading,
      view_all_candidate_matches_link: ai_extra_matches.view_all_candidates_matches_link,
    },
  });
}
export function RoleRouter() {
  const {
    userPrioritizedRole,
    isClient,
    isClientReviewer,
    isRecruiter,
    user,
    viewingOrganization,
    isClientProspect,
  } = useCustomerAuthorizedUserSession();
  const pageLayoutProps = {
    isClientReviewer,
    isRecruiter,
    orgName: viewingOrganization.name,
    user,
    isClientProspect,
    userPrioritizedRole,
  };
  const featureFlags = useTalentHubFlags();

  return (
    <Switch>
      <Route exact path="/role">
        {featureFlags['role-based-rehaul'] ? (
          <ManageRolesDashboardController />
        ) : (
          <ManageRolesController />
        )}
      </Route>
      <CustomRoute
        path="/role/view/:jobID"
        conditionalRedirects={[{ condition: !(isClient || isRecruiter), redirectURL: `/` }]}
      >
        <ViewJobController />
      </CustomRoute>
      <CustomRoute
        path={['/role/create', '/role/edit/:jobID']}
        conditionalRedirects={[{ condition: !(isClient || isRecruiter), redirectURL: `/` }]}
      >
        <CreateEditJobController />
      </CustomRoute>
      <Route
        // ?  I have doubts about whether it is better to use a constants object to name these routes, but I think it is more important to create objects with the links, to standardize the navigation
        path={`/role/:activeRole/:activeTab(${roleTabs.overview}|${roleTabs.submissions}|${roleTabs.interviews}|${roleTabs.offers}|${roleTabs.hires}|${roleTabs.requirements}|${roleTabs.reports})?`}
      >
        <Switch>
          <ActiveRoleController>
            {({
              activeRole,
              roleLayoutProps,
              isQueryLoading,
              upcomingSubmissionCandidates,
              pastSubmissionCandidates,
              interviewsToBeScheduledCandidates,
              upcomingInterviewCandidates,
              pastInterviewCandidates,
              upcomingOffersCandidates,
              pastOffersCandidates,
              hiredCompleteCandidates,
              widgets,
              milestones,
              recentCandidatesActivities,
              weeklySummaries,
              interviewFeedbackUtils,
              job,
              ai_extra_matches,
            }) => (
              <Switch>
                <Route
                  exact
                  path={[
                    `/role/:activeRole/:activeTab(${roleTabs.overview})`,
                    `/role/:activeRole/:activeTab(${roleTabs.overview})/candidate/:candidateID`,
                    `/role/:activeRole/:activeTab(${roleTabs.requirements})`,
                    `/role/:activeRole/:activeTab(${roleTabs.reports})`,
                  ]}
                >
                  <>
                    <Overview
                      roleLayoutProps={{
                        ...roleLayoutProps,
                        pageLayoutProps,
                        showSubHeader: true,
                      }}
                      widgets={widgets}
                      milestones={milestones}
                      recentCandidatesActivities={recentCandidatesActivities}
                      isLoading={isQueryLoading}
                      interviewFeedbackUtils={interviewFeedbackUtils}
                      current_week_summary={weeklySummaries[0] || null}
                      job={job}
                      ai_extra_matches={ai_extra_matches}
                    />
                    <JobRequirements job={job} isLoading={isQueryLoading} />
                    <RoleWeeklyReports
                      weeklySummaries={weeklySummaries}
                      isLoading={isQueryLoading}
                    />
                    <CandidateProfileDrawer />
                  </>
                </Route>
                <Route path={`/role/:activeRole/:activeTab(${roleTabs.submissions})`}>
                  <ScrollToTopOnPathChange />
                  <JobCandidatesBuckets
                    roleLayoutProps={{
                      ...roleLayoutProps,
                      pageLayoutProps,
                      showSubHeader: true,
                    }}
                    candidatesBuckets={[
                      {
                        bucketTitle: 'Submissions Requiring Action',
                        candidates: upcomingSubmissionCandidates,
                        defaultCandidateStatus: 'PENDING',
                        dateColumnTitle: 'Submitted On',
                        pendingActionName: 'Review Candidate',
                      },
                      {
                        bucketTitle: 'Past Submissions',
                        candidates: pastSubmissionCandidates,
                        defaultCandidateStatus: 'COMPLETE',
                      },
                    ]}
                  />
                </Route>
                <Route path={`/role/:activeRole/:activeTab(${roleTabs.interviews})`}>
                  <ScrollToTopOnPathChange />
                  <JobCandidatesBuckets
                    roleLayoutProps={{
                      ...roleLayoutProps,
                      pageLayoutProps,
                      showSubHeader: true,
                    }}
                    candidatesBuckets={[
                      {
                        bucketTitle: 'Interviews – Active',
                        candidates: upcomingInterviewCandidates,
                        defaultCandidateStatus: 'PENDING',
                        pendingActionName: 'View Candidate',
                        shouldShowFeedbackCTA: true,
                      },
                      {
                        bucketTitle: 'Interviews – Scheduling',
                        candidates: interviewsToBeScheduledCandidates,
                        defaultCandidateStatus: 'PENDING',
                        pendingActionName: 'View Candidate',
                        shouldShowFeedbackCTA: true,
                      },
                      {
                        bucketTitle: 'Past Interviews',
                        candidates: pastInterviewCandidates,
                        defaultCandidateStatus: 'COMPLETE',
                        shouldShowFeedbackCTA: true,
                      },
                    ]}
                  />
                </Route>
                <Route path={`/role/:activeRole/:activeTab(${roleTabs.offers})`}>
                  <ScrollToTopOnPathChange />
                  <JobCandidatesBuckets
                    roleLayoutProps={{
                      ...roleLayoutProps,
                      pageLayoutProps,
                      showSubHeader: true,
                    }}
                    candidatesBuckets={[
                      {
                        bucketTitle: 'Active Offers',
                        candidates: upcomingOffersCandidates,
                        defaultCandidateStatus: 'COMPLETE',
                      },
                      {
                        bucketTitle: 'Past Offers',
                        candidates: pastOffersCandidates,
                        defaultCandidateStatus: 'COMPLETE',
                      },
                    ]}
                  />
                </Route>
                <Route path={`/role/:activeRole/:activeTab(${roleTabs.hires})`}>
                  <ScrollToTopOnPathChange />
                  <JobCandidatesBuckets
                    roleLayoutProps={{
                      ...roleLayoutProps,
                      pageLayoutProps,
                      showSubHeader: true,
                    }}
                    candidatesBuckets={[
                      {
                        bucketTitle: 'Hired',
                        candidates: hiredCompleteCandidates,
                        defaultCandidateStatus: 'COMPLETE',
                      },
                    ]}
                  />
                </Route>
                <Route
                  // ! Refactor this route, not should use roleLayout, and also not should be inside inside active-controller
                  path="/role/:activeRole/candidate/:candidateID/feedback"
                >
                  <ScrollToTopOnPathChange />
                  <CandidateFeedbackController roleLayoutProps={roleLayoutProps} />
                </Route>
                <Route
                  // ! Refactor this route, not should use roleLayout, and also not should be inside inside active-controller
                  path="/role/:activeRole/candidate/:candidateID"
                >
                  <ScrollToTopOnPathChange />
                  <CandidateProfileController roleLayoutProps={roleLayoutProps} />
                </Route>
                <CustomRoute
                  // ! This redirect is temporary, to redirect any user to the new overview page
                  path="/role/:activeRole/:activeTab(progress)}"
                  conditionalRedirects={[
                    {
                      condition: true,
                      redirectURL: `/role/${activeRole}/${roleTabs.overview}`,
                    },
                  ]}
                />
                <CustomRoute
                  path="/role/:activeRole"
                  conditionalRedirects={[
                    {
                      condition: true,
                      redirectURL: `/role/${activeRole}/${roleTabs.overview}`,
                    },
                  ]}
                />
              </Switch>
            )}
          </ActiveRoleController>
        </Switch>
      </Route>
    </Switch>
  );
}

export function ClientReviewerRoleRouter() {
  return (
    <Switch>
      <Route path="/role/:activeRole/">
        <ActiveRoleController>
          {({ roleLayoutProps }) => (
            <Switch>
              <Route
                // ! Refactor this route, not should use roleLayout, and also not should be inside inside active-controller
                path="/role/:activeRole/candidate/:candidateID/feedback"
              >
                <CandidateFeedbackController roleLayoutProps={roleLayoutProps} />
              </Route>
              <Route
                // ! Refactor this route, not should use roleLayout, and also not should be inside inside active-controller
                path="/role/:activeRole/candidate/:candidateID"
              >
                <CandidateProfileController roleLayoutProps={roleLayoutProps} />
              </Route>
              <CustomRoute
                path="/role/:activeRole"
                conditionalRedirects={[
                  {
                    condition: true,
                    redirectURL: '/candidates',
                  },
                ]}
              />
            </Switch>
          )}
        </ActiveRoleController>
      </Route>
    </Switch>
  );
}
