import moment from 'moment';
import type {
  RoleDataQuery,
  RoleFirstSubmission,
  RoleOfferAccepted,
  RoleOfferMade,
  RoleFirstInterview,
} from '../../data';

export function serializeToRoleMilestones(data: RoleDataQuery, roleOpenedDate?: string) {
  type DataKeys =
    | 'first_approved'
    | 'first_submission'
    | 'first_interview_scheduled'
    | 'first_offer_made'
    | 'first_offer_accepted';

  type DataKeyNumbers = [DataKeys, number];

  // To return props to show when the milestones when JobOverviewMetrics is fetching
  const createEmptyRoleMilestone = ([,]: DataKeyNumbers) => ({
    completed: false,
    date: '',
    datePrefix: 'Target for',
  });

  const createRoleMilestone = ([dataKey, numberOfDay]: DataKeyNumbers) => {
    const format = 'MMMM D, YYYY';

    // when completed
    if (dataKey === 'first_approved' || (data[dataKey] != null && data[dataKey].length)) {
      let date;

      if (dataKey === 'first_approved') {
        date = roleOpenedDate;
      } else {
        const milestone:
          | RoleFirstSubmission[number]
          | RoleFirstInterview[number]
          | RoleOfferMade[number]
          | RoleOfferAccepted[number]
          | undefined = data[dataKey][0];

        date = milestone?.created_at;
      }

      return {
        completed: true,
        date: moment(date).format(format),
        datePrefix: 'Completed',
      };
    }

    return {
      completed: false,
      date: moment(roleOpenedDate).add(numberOfDay, 'days').format(format),
      datePrefix: 'Target for',
    };
  };

  const dataKeysAndNumbers: DataKeyNumbers[] = [
    ['first_approved', 5],
    ['first_submission', 25],
    ['first_interview_scheduled', 40],
    ['first_offer_made', 70],
    ['first_offer_accepted', 90],
  ];

  const [milestone1, milestone2, milestone3, milestone4, milestone5] = dataKeysAndNumbers.map(
    roleOpenedDate ? createRoleMilestone : createEmptyRoleMilestone,
  );

  return { milestone1, milestone2, milestone3, milestone4, milestone5 };
}
