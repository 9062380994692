import moment from 'moment';
import type {
  MergedPostingAndPosition,
  AllStagesAllBuckets,
  CandidateStageInfo,
  MergedPostingAndPosition as MergedPostingAndPositionGeneric,
} from 'talent-hub/utils';
import {
  calculateRoleAggregatesByStageGraphs,
  capitalizeEachWord,
  createCandidatesStageGraph,
  createCandidateStagesInfo,
  mergePostingsAndPositions,
} from 'talent-hub/utils';
import { compareByObjectsDateAsc, to_friendly_years_of_experience_range } from 'global/utils';
import type { Job_Status_Choices_Enum } from 'global/types';
import {
  candidateSearchDateRange,
  createExploreCandidatesURL,
  to_querystring_of_ai_matching_args,
} from 'talent-hub/shared/features';
import { CandidateInterviewSubStageName } from 'talent-hub/constants';
import type { CandidateWorkflowStatus } from 'talent-hub/types';
import { serializeCandidateBrowse } from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/ExploreCandidates.serializer';
import type { SelectDashboardBrowseCandidatesQuery } from 'talent-hub/role/prospect/dashboard/data';
import { type SelectAiCandidatesMatchingJobMutationVariables } from './dashboard-roles/data';
import type { SelectDashboardQuery } from './data';

export type PostingWithTotals = MergedPostingAndPosition<
  SelectDashboardQuery['active_postings'][number]
> & {
  openHeadCount: number;
  closedHeadCount: number | null;
  /**
   * This is the total number of submitted candidates. Hires are not included.
   * @todo: Rename this to total_candidate_submission
   */
  totalCandidates: number;
  totalActiveCandidates: number;
  totalHired: number;
  candidates_to_review_per_role: CandidateToReviewInfoWithStatusInfo[];
  candidates_ai_matching_args: SelectAiCandidatesMatchingJobMutationVariables;
  must_have_skills: string[];
  explore_candidate_applied_filter_link: string;
};

export type PendingJob = {
  id: number;
  name: string;
  status: Job_Status_Choices_Enum;
  positions: number;
  yoe: string;
  topSkills: string;
  must_have_skills: string[];
  candidates_ai_matching_args: SelectAiCandidatesMatchingJobMutationVariables;
  explore_candidate_applied_filter_link: string;
};

type CandidatesToReviewStages = Pick<
  AllStagesAllBuckets<CandidateToReviewStagingInfo>,
  'submission' | 'interview'
>;

// TODO: Must move into a shared folder where both dahsboard and manage candidates serializer can import it from
export function candidateFriendlyStatusInfo(
  status: string | undefined,
  hasUserFeedback: boolean,
  hasOtherFeedback: boolean,
): {
  status: string;
  friendlyStatustext: string;
  linkText: string;
} {
  // Product decided to use Awaiting Your Review and the warning color as a default state because it's a generic enough status to apply to just about any situation
  if (!status) {
    return {
      friendlyStatustext: 'Awaiting Your Review',
      status: 'warning',
      linkText: 'View Candidate',
    };
  }

  const feedbackStatuses = [
    'Client Review - Interview #1 - Initial Interview',
    'Client Review - Interview #2 - Technical Interview / Assessment',
    'Client Review - Interview #3 - Final interview',
    'Client Review - Interview #4 - Additional Interview',
    'Client Review - Interview #1',
    'Client Review - Interview #2',
    'Client Review - Interview #3',
    'Client Review - Interview #4',
    'Client Review - Interview #2 to be Scheduled',
    'Client Review - Interview #3 to be Scheduled',
    'Client Review - Interview #4 to be Scheduled',
  ];

  switch (true) {
    case status === 'Submitted to Client - Submitted':
      return {
        friendlyStatustext: 'Awaiting Your Review',
        status: 'warning',
        linkText: 'Review Candidate',
      };

    case [
      'Client Review - Interview Completed',
      'Client Review - Awaiting Client Feedback',
      'Client Review - Final Interview Completed',
    ]
      .concat(feedbackStatuses)
      .includes(status) && !hasUserFeedback:
      return {
        friendlyStatustext: 'Ready for Feedback',
        status: 'info',
        linkText: 'Leave Feedback',
      };

    case feedbackStatuses.includes(status) && hasOtherFeedback:
      return {
        friendlyStatustext: 'Review Interview Feedback',
        status: 'info',
        linkText: 'Review Feedback',
      };

    case [
      'Client Review - Interview Scheduled',
      'Submitted to Client - Client Portal Advance',
      'Client Review - Interview to be Scheduled',
      'Client Review - Interview #1 to be Scheduled',
      'Client Review - Interview #2 to be Scheduled',
      'Client Review - Interview #3 to be Scheduled',
      'Client Review - Interview #4 to be Scheduled',
    ].includes(status):
      return {
        friendlyStatustext: 'Scheduling Interview',
        status: 'info',
        linkText: 'View Candidate',
      };

    default:
      return {
        friendlyStatustext: 'Awaiting Your Review',
        status: 'warning',
        linkText: 'View Candidate',
      };
  }
}

function groupCandidatesToDashboardCandidatesToReview({
  submission,
  interview,
}: CandidatesToReviewStages): {
  upcomingSubmissionCandidates: CandidateToReviewInfoWithStatusInfo[];
  upcomingInterviewCandidates: CandidateToReviewInfoWithStatusInfo[];
  interviewsToBeScheduledCandidates: CandidateToReviewInfoWithStatusInfo[];
} {
  const pickProfileData = (candidateProfile: CandidateToReviewStagingInfo) => {
    const hasUserFeedback: boolean =
      (candidateProfile.candidateToReviewInfo.user_feedback?.length ?? 0) > 0;
    const hasOtherFeedback: boolean =
      (candidateProfile.candidateToReviewInfo.other_feedback?.length ?? 0) > 0;

    const { friendlyStatustext, status, linkText } = candidateFriendlyStatusInfo(
      candidateProfile.candidateToReviewInfo.statusText,
      hasUserFeedback,
      hasOtherFeedback,
    );

    return {
      ...candidateProfile.candidateToReviewInfo,
      hasUserFeedback,
      statusText: friendlyStatustext,
      status,
      linkText,
    };
  };
  const upcomingInterviewCandidatesUnMapped: CandidateToReviewStagingInfo[] =
    interview.inProgress.buckets[CandidateInterviewSubStageName.Scheduled] || [];

  const interviewsToBeScheduledCandidatesUnMapped: CandidateToReviewStagingInfo[] =
    interview.inProgress.buckets[CandidateInterviewSubStageName.ToBeScheduled] || [];

  const filterCandidates = ({
    statusText,
    hasUserFeedback,
  }: {
    statusText: string;
    hasUserFeedback: boolean;
  }) => {
    // User has already left a feedback
    if (statusText === 'Awaiting Your Review' && hasUserFeedback) {
      return false;
    }

    return true;
  };

  return {
    upcomingSubmissionCandidates: submission.inProgress.all
      .map(pickProfileData)
      .sort(compareByObjectsDateAsc),
    upcomingInterviewCandidates: upcomingInterviewCandidatesUnMapped
      .map(pickProfileData)
      .filter(filterCandidates)
      .sort(compareByObjectsDateAsc),
    interviewsToBeScheduledCandidates: interviewsToBeScheduledCandidatesUnMapped
      .map(pickProfileData)
      .filter(filterCandidates)
      .sort(compareByObjectsDateAsc),
  };
}

export type CandidateToReviewInfo = {
  jobID?: number | null;
  id?: number | null;
  name: string;
  statusText?: string;
  date?: string;
  role: string;
  user_feedback?: {
    id: number;
  }[];
  other_feedback?: {
    id: number;
  }[];
};

export type CandidateToReviewInfoWithStatusInfo = CandidateToReviewInfo & {
  status: string;
  linkText: string;
};

type CandidateToReviewStagingInfo = {
  candidateToReviewInfo: CandidateToReviewInfo;
} & CandidateStageInfo;

type MergedPostingAndPositions = MergedPostingAndPositionGeneric<
  SelectDashboardQuery['active_postings'][number]
>[];

type DashboardApplicantWorkflow = MergedPostingAndPositions[number]['applicant_workflows'][number];

function transformToCandidatesWithPartialStageInfo(
  applicantWorkFlows: DashboardApplicantWorkflow[],
  postingName: MergedPostingAndPosition<SelectDashboardQuery['active_postings'][number]>['name'],
): CandidateToReviewStagingInfo[] {
  return applicantWorkFlows.map(
    ({ job_profile_id, icims_person, workflow_statuses, workflow_profile }) => {
      const statuses = workflow_statuses.map(
        ({ date, status }): CandidateWorkflowStatus => ({
          date,
          title: status?.title || '',
        }),
      );

      const { currentStatus, ...rest } = createCandidateStagesInfo(statuses);

      return {
        ...rest,
        currentStatus,
        candidateToReviewInfo: {
          jobID: job_profile_id,
          id: icims_person?.profile_id,
          name: `${capitalizeEachWord(
            icims_person?.candidate?.firstname || icims_person?.firstname,
          )} ${capitalizeEachWord(icims_person?.candidate?.lastname || icims_person?.lastname)}`,
          statusText: currentStatus?.title,
          date: moment(currentStatus?.date).format('MMM D, YYYY'),
          role: postingName || '-',
          user_feedback: workflow_profile?.user_feedback,
          other_feedback: workflow_profile?.other_feedback,
        },
      };
    },
  );
}

function augmentPostingWithTotals(
  postings: MergedPostingAndPosition<SelectDashboardQuery['active_postings'][number]>[],
): PostingWithTotals[] {
  return postings.map((posting) => {
    const stagedCandidates = transformToCandidatesWithPartialStageInfo(
      posting.applicant_workflows,
      posting.name,
    );
    const candidateStageGraph = createCandidatesStageGraph(stagedCandidates);

    const {
      upcomingSubmissionCandidates,
      upcomingInterviewCandidates,
      interviewsToBeScheduledCandidates,
    } = groupCandidatesToDashboardCandidatesToReview({
      submission: candidateStageGraph.submission,
      interview: candidateStageGraph.interview,
    });

    const {
      totalCandidates: total_submitted_candidates,
      totalActiveCandidates,
      totalHired,
    } = calculateRoleAggregatesByStageGraphs(candidateStageGraph);

    const exploreCandidateArgs_countries = posting.job?.job_acceptable_locations
      ?.map(({ location: { country } }) => country)
      .filter((country) => country !== null);

    const candidates_ai_matching_args: SelectAiCandidatesMatchingJobMutationVariables = {
      jobId: posting.id,
      queryString: to_querystring_of_ai_matching_args({
        title: posting.job?.title,
        description: posting.job?.about,
        job_required_skills:
          posting.job?.job_required_skills.flatMap(({ skill }) => skill.name) || [],
        job_tech_stack: posting.job?.job_tech_stack.flatMap(({ skill }) => skill.name) || [],
        what_youll_do: posting.job?.what_youll_do,
        what_youll_bring: posting.job?.what_youll_bring,
      }),
      countries: exploreCandidateArgs_countries || [],
      employmentType: posting.job?.employment_type || undefined,
      latestLastCurationWorkflowsUpdate: candidateSearchDateRange,
    };

    return {
      ...posting,
      totalCandidates: total_submitted_candidates,
      totalActiveCandidates,
      totalHired,
      candidates_to_review_per_role: upcomingSubmissionCandidates.concat(
        upcomingInterviewCandidates,
        interviewsToBeScheduledCandidates,
      ),
      must_have_skills: posting.job?.job_required_skills?.map(({ skill }) => skill.name) || [],
      candidates_ai_matching_args,
      explore_candidate_applied_filter_link: createExploreCandidatesURL({
        countries: exploreCandidateArgs_countries?.length ? exploreCandidateArgs_countries : null,
        employment_type: posting.job?.employment_type || null,
        candidate_role: posting.job?.role_type || null,
        sort_option: '',
        must_have_skill_ids: [],
        regions: null,
        badges: null,
        should_have_skill_ids: [],
        min_years_of_experience: null,
      }),
    };
  });
}

function augmentPendingJob(jobs: SelectDashboardQuery['job'][number][]): PendingJob[] {
  return jobs.map((job) => {
    const exploreCandidateArgs_countries = job?.job_acceptable_locations
      ?.map(({ location: { country } }) => country)
      .filter((country) => country !== null);

    const candidates_ai_matching_args: SelectAiCandidatesMatchingJobMutationVariables = {
      jobId: job.id,
      queryString: to_querystring_of_ai_matching_args({
        title: job?.title,
        description: job?.about,
        job_required_skills: job?.job_required_skills.flatMap(({ skill }) => skill.name) || [],
        job_tech_stack: job?.job_tech_stack.flatMap(({ skill }) => skill.name) || [],
        what_youll_do: job?.what_youll_do,
        what_youll_bring: job?.what_youll_bring,
      }),
      countries: exploreCandidateArgs_countries || [],
      employmentType: job?.employment_type || null,
      latestLastCurationWorkflowsUpdate: candidateSearchDateRange,
    };

    return {
      id: job.id,
      name: job.title || '-',
      status: job.status,
      positions: job.job_positions_aggregate.aggregate?.count || 0,
      yoe: to_friendly_years_of_experience_range(job.min_years_of_experience) || '-',
      topSkills: job.job_required_skills.map<string>(({ skill }) => skill.name)?.join(', ') || '-',
      must_have_skills: job?.job_required_skills?.map(({ skill }) => skill.name) || [],
      candidates_ai_matching_args,
      explore_candidate_applied_filter_link: createExploreCandidatesURL({
        countries: exploreCandidateArgs_countries?.length ? exploreCandidateArgs_countries : null,
        employment_type: job?.employment_type || null,
        candidate_role: job?.role_type || null,
        sort_option: '',
        must_have_skill_ids: [],
        regions: null,
        badges: null,
        should_have_skill_ids: [],
        min_years_of_experience: null,
      }),
    };
  });
}

export function serializeDashboard({
  data,
  bookmarkedRoleIDs,
}: {
  data?: SelectDashboardQuery;
  bookmarkedRoleIDs: number[];
}): {
  totalSubmittedCandidates: number;
  totalActiveMembers: number;
  totalOpenRoles: number;
  totalOpenPositions: number;
  totalActiveCandidates: number;
  hasMoreRoles: boolean;
  roles: {
    active: PostingWithTotals[];
    pending: PendingJob[];
  };
} {
  if (!data) {
    return {
      totalSubmittedCandidates: 0,
      totalActiveMembers: 0,
      totalOpenRoles: 0,
      totalOpenPositions: 0,
      totalActiveCandidates: 0,
      hasMoreRoles: false,
      roles: {
        active: [],
        pending: [],
      },
    };
  }

  const postings = mergePostingsAndPositions(data.active_postings);
  const augmentedPostings = augmentPostingWithTotals(postings);

  const active_roles_aggregates = augmentedPostings.reduce(
    (acc, augmentedPosting) => {
      return {
        total_active_candidates:
          acc.total_active_candidates + augmentedPosting.totalActiveCandidates,
        total_open_head_counts: acc.total_open_head_counts + augmentedPosting.openHeadCount,
        total_candidate_submission:
          acc.total_candidate_submission + augmentedPosting.totalCandidates,
      };
    },
    {
      total_active_candidates: 0,
      total_open_head_counts: 0,
      total_candidate_submission: 0,
    },
  );

  const sorted_active_roles = augmentedPostings.sort(
    (a, b) => Number(bookmarkedRoleIDs.includes(b.id)) - Number(bookmarkedRoleIDs.includes(a.id)),
  );

  const pendingRoles = augmentPendingJob(data.job);

  const selectedActiveRolesToShow = sorted_active_roles.slice(0, 3);
  const selectedPendingRolesToShow = pendingRoles.slice(0, 3 - selectedActiveRolesToShow.length);

  return {
    totalSubmittedCandidates: active_roles_aggregates.total_candidate_submission, // Not used in the UI. Since the logic and tests are there, its probably best to keep it to maintain it
    totalActiveMembers: data.members?.aggregate?.count || 0,
    totalOpenRoles: sorted_active_roles.length,
    totalOpenPositions: active_roles_aggregates.total_open_head_counts,
    totalActiveCandidates: active_roles_aggregates.total_active_candidates,
    hasMoreRoles: sorted_active_roles.length + pendingRoles.length > 3,
    roles: {
      active: selectedActiveRolesToShow,
      pending: selectedPendingRolesToShow,
    },
  };
}

// TODO: Fix types
export function serializeDashboardOtherGreatCandidates(
  data: Omit<SelectDashboardBrowseCandidatesQuery, 'location'>,
) {
  if (!data) {
    return {
      recentlyActive: [],
      topCandidateExperience: [],
      zeroOneExperience: [],
      techLeadExperience: [],
    };
  }
  const {
    candidate_browse_recently_active,
    candidate_top_company_experience,
    candidate_zero_one_experience,
    candidate_tech_lead,
  } = data;

  const { candidates: recentlyActive } = serializeCandidateBrowse({
    data: candidate_browse_recently_active
      ? {
          candidate_browse: candidate_browse_recently_active,
        }
      : undefined,
    isSortByRecommended: false,
    isLoadingCandidates: false,
    highlightBadgeType: null,
  });
  const { candidates: topCandidateExperience } = serializeCandidateBrowse({
    data: candidate_top_company_experience
      ? {
          candidate_browse: candidate_top_company_experience,
        }
      : undefined,

    isSortByRecommended: false,
    isLoadingCandidates: false,
    highlightBadgeType: null,
  });
  const { candidates: zeroOneExperience } = serializeCandidateBrowse({
    data: candidate_zero_one_experience
      ? {
          candidate_browse: candidate_zero_one_experience,
        }
      : undefined,
    isSortByRecommended: false,
    isLoadingCandidates: false,
    highlightBadgeType: null,
  });
  const { candidates: techLeadExperience } = serializeCandidateBrowse({
    data: candidate_tech_lead
      ? {
          candidate_browse: candidate_tech_lead,
        }
      : undefined,
    isSortByRecommended: false,
    isLoadingCandidates: false,
    highlightBadgeType: null,
  });

  return {
    recentlyActive,
    topCandidateExperience,
    zeroOneExperience,
    techLeadExperience,
  };
}
