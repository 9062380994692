import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import type {
  SelectAiCandidatesMatchingJobMutation,
  SelectAiCandidatesMatchingJobMutationVariables,
} from './data';
import { type PendingJob } from '../Dashboard.serializer';
import { DashboardPendingRole } from './DashboardPendingRole';

import { SelectAICandidatesMatchingJob } from './data';
import { dashboard_pending_role_serializer } from './DashboardRole.serializer';

export function DashboardPendingRolesController({ role }: { role: PendingJob }) {
  const history = useHistory();
  const [candidates_by_ai_matching_mutation, { data, loading, error }] = useMutation<
    SelectAiCandidatesMatchingJobMutation,
    SelectAiCandidatesMatchingJobMutationVariables
  >(SelectAICandidatesMatchingJob, {
    variables: role.candidates_ai_matching_args,
    context: {
      service: 'service',
    },
  });

  if (error) throw error;

  useEffect(() => {
    candidates_by_ai_matching_mutation();
    // We don't want to run this effect again irrespective of anything changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pending_role = dashboard_pending_role_serializer(data, role);

  return (
    <DashboardPendingRole
      id={pending_role.id.toString()}
      name={pending_role.name || ''}
      positions_count={pending_role.positions_count}
      yoe={pending_role.yoe}
      topSkills={pending_role.topSkills}
      status={pending_role.status}
      currentRoleCandidateMatches={pending_role.currentRoleCandidateMatches}
      handleCandidateClick={(candidate_id) => {
        history.push(`/dashboard/candidate/${candidate_id}`); // TODO: I think this should be a router link instead?
      }}
      view_all_candidate_matches_link={pending_role.view_all_candidate_matches_link}
      is_loading_candidate_matches_by_role={loading}
    />
  );
}
