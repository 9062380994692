import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Heading,
  Grid,
  Button,
  Text,
  Badge,
  Flex,
  Skeleton,
  Divider,
  Accordion,
  SearchUserIcon,
} from '@terminal/design-system';
import { Job_Status_Choices_Enum } from 'global/types';
import { Card, CardBody, CardHeader } from 'talent-hub/components';
import { NewDashboardCandidateCarousel } from 'talent-hub/shared/features/dashboard';
import { ExpandIcon } from '../components';

export function DashboardPendingRole({
  id,
  name,
  positions_count,
  yoe,
  topSkills,
  status,
  currentRoleCandidateMatches, // TODO: rename to current_role_candidate_matches
  handleCandidateClick,
  is_loading_candidate_matches_by_role,
  view_all_candidate_matches_link,
}: {
  name: string;
  positions_count: number;
  yoe: string;
  topSkills: string;
  status: Job_Status_Choices_Enum;
  id: string;
  currentRoleCandidateMatches: {
    id: number;
    firstName: string;
    lastName?: string;
    last_name_initial?: string;
    currentRole: string;
    yearsExperience: string;
    city?: string;
    country_choice?: {
      name: string;
    };
    skills: Array<{
      id: number | string;
      name: string;
      isSelected: boolean;
    }>;
    remainingSkills?: number;
  }[];
  handleCandidateClick: (candidate: any) => void;
  view_all_candidate_matches_link: string;
  is_loading_candidate_matches_by_role: boolean;
}) {
  return (
    <Card p={0} h="auto" w="full" gap={0}>
      <CardHeader p={6} bgColor="ui.inverse.secondary" justifyContent="space-between">
        <Flex flexDir="column" w="full">
          <Heading variant="heading-3" display="block" mb={1} noOfLines={1}>
            {name}
          </Heading>
          <Flex
            flexWrap="nowrap"
            flexDir={{
              base: 'column',
              sm: 'row',
            }}
            w="ull"
          >
            <Box>
              <Text display="inline-block" variant="caption" noOfLines={1}>
                Positions:&ensp;
                <strong>{positions_count}</strong>
              </Text>
            </Box>
            <Divider
              height={6}
              orientation="vertical"
              mx={3}
              display={{ base: 'none', sm: 'block' }}
            />
            <Box>
              <Text display="inline-block" variant="caption" noOfLines={1}>
                Experience:&ensp;<strong>{yoe}</strong>
              </Text>
            </Box>
            <Divider
              height={6}
              orientation="vertical"
              mx={3}
              display={{ base: 'none', sm: 'block' }}
            />
            <Box>
              <Text display="inline-block" variant="caption" noOfLines={1}>
                Skills:&ensp;<strong>{topSkills}</strong>
              </Text>
            </Box>
            <Box
              ml={{
                base: 0,
                sm: 'auto',
              }}
            >
              {status === Job_Status_Choices_Enum.Draft ? (
                <Badge colorScheme="warning" fontSize="sm">
                  Draft
                </Badge>
              ) : (
                <Badge colorScheme="primary" fontSize="sm">
                  Pending Approval
                </Badge>
              )}
            </Box>
          </Flex>
        </Flex>
        <Flex alignItems="center">
          <Divider orientation="vertical" mx={3} />
          {status === Job_Status_Choices_Enum.Draft ? (
            <Button
              variant="ghost"
              colorScheme="primary"
              to={{
                pathname: `/role/edit/${id}`,
                state: { from: 'dashboard' },
              }}
              as={RouterLink}
              rightIcon={<ExpandIcon />}
            >
              Edit Draft
            </Button>
          ) : (
            <Button
              variant="ghost"
              colorScheme="primary"
              to={{
                pathname: `/role/view/${id}`,
                state: { from: 'dashboard' },
              }}
              as={RouterLink}
              rightIcon={<ExpandIcon />}
            >
              View Role
            </Button>
          )}
        </Flex>
      </CardHeader>
      <CardBody flexDir="column" p={0} py={4}>
        <Accordion
          px={4}
          py={0}
          arrowColor="brand.main"
          defaultIndex={[0]}
          customDivider={<Divider mt={4} mb={4} />}
          accordions={[
            {
              parent: (
                <>
                  <Heading variant="heading-4" mr={2} display="inline-block">
                    Candidate Matches to Explore
                  </Heading>
                  <SearchUserIcon width={6} height={6} />
                </>
              ),
              children: is_loading_candidate_matches_by_role ? (
                <Grid templateColumns="1fr 1fr 1fr" gap={6} p={6} height={48} mb={4}>
                  <Skeleton height="full" width="full" />
                  <Skeleton height="full" width="full" />
                  <Skeleton height="full" width="full" />
                </Grid>
              ) : (
                <NewDashboardCandidateCarousel
                  showDisplayEmptyMessage
                  shouldDeferInitialization
                  key={`candidates-carousel-${id}`}
                  candidates={currentRoleCandidateMatches}
                  onItemClick={handleCandidateClick}
                  maxElements={5}
                  shouldDisplayViewAll={currentRoleCandidateMatches.length >= 3}
                  viewAllHref={view_all_candidate_matches_link}
                />
              ),
            },
          ]}
        />
      </CardBody>
    </Card>
  );
}
