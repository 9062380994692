import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import {
  Box,
  Text,
  Grid,
  Heading,
  Skeleton,
  Button,
  useDisclosure,
  Divider,
  Flex,
  GridItem,
  HStack,
  Center,
  AssessmentsIcon,
  List,
  ListItem,
} from '@terminal/design-system';
import { Card, CardBody, CardHeader } from 'talent-hub/components';
import { NewDashboardCandidateCarousel } from 'talent-hub/shared/features/dashboard';
import type { ProfileInfo } from '../../Role.types';
import {
  RoleLayout,
  roleTabs,
  JobsIcon,
  PeopleIcon,
  InviteToFeedbackModal,
} from '../../components';
import { ProgressCandidates, RoleMilestone } from './components';

/**
 * TODO: Rename this file to Overview.tsx
 */
export function Overview({
  roleLayoutProps,
  widgets,
  milestones: { milestone1, milestone2, milestone3, milestone4, milestone5 },
  recentCandidatesActivities,
  isLoading,
  interviewFeedbackUtils,
  current_week_summary,
  job,
  ai_extra_matches: {
    current_role_candidate_matches,
    is_loading_current_role_candidate_matches,
    view_all_candidate_matches_link,
  },
}: {
  roleLayoutProps: React.ComponentProps<typeof RoleLayout>;
  widgets: {
    totalSubmissions: number | null;
    totalActiveCandidates: number | null;
    totalPositions: number | null;
    totalHired: number | null;
  };
  milestones: {
    milestone1?: {
      completed: boolean;
      date: string;
      datePrefix: string;
    };
    milestone2?: {
      completed: boolean;
      date: string;
      datePrefix: string;
    };
    milestone3?: {
      completed: boolean;
      date: string;
      datePrefix: string;
    };
    milestone4?: {
      completed: boolean;
      date: string;
      datePrefix: string;
    };
    milestone5?: {
      completed: boolean;
      date: string;
      datePrefix: string;
    };
  };
  recentCandidatesActivities: ProfileInfo[];
  isLoading: boolean;
  interviewFeedbackUtils: {
    isLoading: boolean;
    users: { value: string; label: string }[];
    handleOnSubmitInviteFeedback: (currentValues: {
      users: {
        value: string;
        label: string;
      }[];
      isRoleAccess: boolean;
    }) => { error: boolean };
  };
  current_week_summary: {
    dateRange: string;
    bucketSummaries: {
      title: string;
      week: { total: number };
      allTime: number;
    }[];
  } | null;
  job: {
    employment_type: string | null;
    experiences: string[];
    requiredSkills: string[];
    location: string;
  } | null;
  ai_extra_matches: {
    current_role_candidate_matches: {
      id: number;
      firstName: string;
      lastName?: string;
      last_name_initial?: string;
      currentRole: string;
      yearsExperience: string;
      city?: string;
      country_choice?: {
        name: string;
      };
      skills: Array<{
        id: number | string;
        name: string;
        isSelected: boolean;
      }>;
      remainingSkills?: number;
    }[];
    is_loading_current_role_candidate_matches: boolean;
    view_all_candidate_matches_link: string;
  };
}) {
  const history = useHistory();
  const { activeRole } = useParams<{ activeRole: string }>();
  const pathRoot = `/role/${activeRole}/`;
  const feedbackModal = useDisclosure();

  return (
    <RoleLayout {...roleLayoutProps}>
      <InviteToFeedbackModal
        options={interviewFeedbackUtils.users}
        isLoading={interviewFeedbackUtils.isLoading}
        isOpen={feedbackModal.isOpen}
        onClose={feedbackModal.onClose}
        modalTitle="Invite Your Team"
        modalDescription="Team members will be able to see candidate profiles and leave interview feedback for all active candidates for this role. Compensation information will be hidden."
        handleOnSubmitInviteFeedback={interviewFeedbackUtils.handleOnSubmitInviteFeedback}
        shouldHide_candidateCheckbox
      />
      <Grid
        templateColumns={{
          base: '1fr',
          md: '4fr 2fr',
          xl: '5fr 2fr',
        }}
        gap={6}
      >
        <GridItem>
          <Grid
            templateColumns={{
              base: '1fr',
              md: '1fr 1fr',
            }}
            gap={6}
          >
            <GridItem>
              <Card h="full">
                <CardHeader>
                  <Heading variant="heading-3" mr={2} color="brand.darker">
                    Role Requirements
                  </Heading>
                </CardHeader>
                <CardBody px={2}>
                  <Box mb={3}>
                    <Skeleton isLoaded={!isLoading && !!job}>
                      <List spacing={2}>
                        <ListItem>
                          <Text variant="label">Employment Type</Text>
                          <Text>{job?.employment_type}</Text>
                        </ListItem>
                        <ListItem>
                          <Text variant="label">Experience</Text>
                          <Text>{job?.experiences.join(', ')}</Text>
                        </ListItem>
                        <ListItem>
                          <Text variant="label">Required Skills</Text>
                          <Text>{job?.requiredSkills.join(', ')}</Text>
                        </ListItem>
                        <ListItem>
                          <Text variant="label">Location</Text>
                          <Text>{job?.location}</Text>
                        </ListItem>
                      </List>
                    </Skeleton>
                  </Box>
                  <Button
                    alignSelf="end"
                    marginTop="auto"
                    size="md"
                    variant="outline"
                    w="full"
                    to={`${pathRoot}${roleTabs.requirements}`}
                    as={RouterLink}
                  >
                    View All Requirements
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem>
              <Card h="full">
                <CardHeader flexDir="column">
                  <Heading variant="heading-3" mr={2} color="brand.darker">
                    Last Week&apos;s Activity
                  </Heading>
                  <Text>{current_week_summary?.dateRange}</Text>
                </CardHeader>
                <CardBody px={2}>
                  <Skeleton isLoaded={!isLoading} h="full" display="flex" flexDir="column">
                    {current_week_summary ? (
                      <>
                        {current_week_summary.bucketSummaries.map((bucketSummary) => (
                          <Box
                            key={`bucket-summary${current_week_summary.dateRange}-${bucketSummary.title}`}
                            mb={3}
                          >
                            <HStack pl={2} py={2} borderLeft="4px" borderLeftColor="brand.main">
                              <Text>{bucketSummary.title}:</Text>
                              <Text variant="label">{bucketSummary.week.total}</Text>
                              <Divider orientation="vertical" py={3} />
                              <Text>All Time: </Text>
                              <Text variant="label">{bucketSummary.allTime}</Text>
                            </HStack>
                          </Box>
                        ))}
                        <Button
                          marginTop="auto"
                          size="md"
                          variant="outline"
                          w="full"
                          to={`${pathRoot}${roleTabs.reports}`}
                          as={RouterLink}
                        >
                          See All Activity
                        </Button>
                      </>
                    ) : (
                      <Center flexDir="column">
                        <AssessmentsIcon
                          display="block"
                          w={16}
                          h={16}
                          my={4}
                          color="text.disabled"
                        />
                        <Heading variant="heading-4" color="text.disabled">
                          No Report Available
                        </Heading>
                      </Center>
                    )}
                  </Skeleton>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem colSpan={{ base: 1, md: 2 }} overflowX="hidden">
              <ProgressCandidates
                loading={isLoading}
                recentCandidatesActivities={recentCandidatesActivities}
              />
            </GridItem>
            <GridItem
              colSpan={{ base: 1, md: 2 }}
              // * To avoid overflow table component on mobile
              overflowX="hidden"
            >
              <Card h="full">
                <CardHeader>
                  <Heading variant="heading-3" mr={2} color="brand.darker">
                    More Candidate Matches to Explore
                  </Heading>
                </CardHeader>
                <CardBody>
                  {is_loading_current_role_candidate_matches ? (
                    <Grid templateColumns="1fr 1fr 1fr" gap={6} p={6} height={48} mb={4}>
                      <Skeleton height="full" width="full" />
                      <Skeleton height="full" width="full" />
                      <Skeleton height="full" width="full" />
                    </Grid>
                  ) : (
                    <NewDashboardCandidateCarousel
                      showDisplayEmptyMessage
                      shouldDeferInitialization
                      candidates={current_role_candidate_matches}
                      onItemClick={(candidate_id) => {
                        history.push(`${pathRoot}${roleTabs.overview}/candidate/${candidate_id}`);
                      }}
                      maxElements={5}
                      shouldDisplayViewAll={current_role_candidate_matches.length >= 3}
                      viewAllHref={view_all_candidate_matches_link}
                    />
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem
          pl={{ base: 0, lg: 6 }}
          borderLeftColor="ui.secondary"
          borderLeftWidth={{
            base: 'none',
            lg: '1px',
          }}
        >
          <Grid
            templateColumns={{
              base: '1fr',
              sm: '1fr 1fr',
              md: '1fr',
            }}
            gap={{ base: 6, md: 6 }}
            alignItems="center"
          >
            <Grid gap={4} templateColumns="1fr">
              <GridItem
                px={4}
                py={3}
                bgColor="bg.secondary"
                alignContent="center"
                display="flex"
                flexDir="column"
                alignItems="start"
              >
                <Flex w="full">
                  <JobsIcon width={12} height="full" mr={4} />
                  <Flex direction="column" w="full">
                    <Text>
                      <Skeleton isLoaded={!isLoading} display="inline-block">
                        <strong>{widgets.totalHired}</strong>
                      </Skeleton>
                      &#32; Hires made
                      <br />
                      from &#32;
                      <Skeleton isLoaded={!isLoading} display="inline-block">
                        <strong>{widgets.totalPositions}</strong>
                      </Skeleton>
                      &#32; positions
                    </Text>
                  </Flex>
                </Flex>
              </GridItem>
              <GridItem
                px={4}
                py={3}
                bgColor="bg.secondary"
                alignContent="center"
                display="flex"
                flexDir="column"
                alignItems="start"
              >
                <Flex w="full">
                  <PeopleIcon width={12} h="full" mr={4} />
                  <Flex direction="column" w="full">
                    <Text>
                      <Skeleton isLoaded={!isLoading} display="inline-block">
                        <strong>{widgets.totalActiveCandidates}</strong>
                      </Skeleton>
                      &#32; Active Candidates
                      <br />
                      from&#32;
                      <Skeleton isLoaded={!isLoading} display="inline-block">
                        <strong>{widgets.totalSubmissions}</strong>
                      </Skeleton>
                      &#32;submissions
                    </Text>
                  </Flex>
                </Flex>
              </GridItem>
            </Grid>
            <Divider display={{ base: 'none', lg: 'block' }} />
            <GridItem bgColor="bg.primary" px={4}>
              <Heading variant="heading-3">Invite Your Team</Heading>
              <Text>Let others review candidates for this role</Text>
              <Button variant="outline" w="full" mt={4} onClick={feedbackModal.onOpen}>
                Add Reviewers
              </Button>
            </GridItem>
            <Divider display={{ base: 'none', lg: 'block' }} />
            <GridItem>
              <Heading variant="heading-3" px={4}>
                Milestones
              </Heading>
              {milestone1 && (
                <RoleMilestone
                  milestoneNumber={1}
                  milestoneTitle="Role Activated"
                  buttonText="View Role Requirements"
                  buttonRoute={`${pathRoot}${roleTabs.requirements}`}
                  date={milestone1.date}
                  datePrefix={milestone1.datePrefix}
                  completed={milestone1.completed}
                  isLoading={isLoading}
                />
              )}
              {milestone2 && (
                <RoleMilestone
                  milestoneNumber={2}
                  milestoneTitle="First Submission"
                  buttonText="View All Submissions"
                  buttonRoute={`${pathRoot}${roleTabs.submissions}`}
                  date={milestone2.date}
                  datePrefix={milestone2.datePrefix}
                  completed={milestone2.completed}
                  isLoading={isLoading}
                />
              )}
              {milestone3 && (
                <RoleMilestone
                  milestoneNumber={3}
                  milestoneTitle="First Interview Scheduled"
                  buttonText="View All Interviews"
                  buttonRoute={`${pathRoot}${roleTabs.interviews}`}
                  date={milestone3.date}
                  datePrefix={milestone3.datePrefix}
                  completed={milestone3.completed}
                  isLoading={isLoading}
                />
              )}
              {milestone4 && (
                <RoleMilestone
                  milestoneNumber={4}
                  milestoneTitle="First Offer Made"
                  buttonText="View All Offers"
                  buttonRoute={`${pathRoot}${roleTabs.offers}`}
                  date={milestone4.date}
                  datePrefix={milestone4.datePrefix}
                  completed={milestone4.completed}
                  isLoading={isLoading}
                />
              )}
              {milestone5 && (
                <RoleMilestone
                  milestoneNumber={5}
                  milestoneTitle="Offer Signed"
                  buttonText="View All Offers"
                  buttonRoute={`${pathRoot}${roleTabs.offers}`}
                  date={milestone5.date}
                  datePrefix={milestone5.datePrefix}
                  completed={milestone5.completed}
                  isLoading={isLoading}
                />
              )}
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </RoleLayout>
  );
}
