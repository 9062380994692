import type { ComponentSerializerProps } from 'talent-hub/utils';
import { to_matching_candidates } from 'talent-hub/shared/features';
import { type SelectAiCandidatesMatchingJobMutation } from './data';
import { type PendingJob, type PostingWithTotals } from '../Dashboard.serializer';
import { type DashboardActiveRole } from './DashboardActiveRole';
import { type DashboardPendingRole } from './DashboardPendingRole';

export function dashboard_active_role_serializer(
  data: SelectAiCandidatesMatchingJobMutation | null | undefined,
  role: PostingWithTotals,
): ComponentSerializerProps<typeof DashboardActiveRole> {
  return {
    currentRoleCandidateMatches: to_matching_candidates(data, role.must_have_skills),
    name: role.name || '',
    id: role.id.toString(),
    totalActiveCandidates: role.totalActiveCandidates,
    openHeadCount: role.openHeadCount,
    totalHired: role.totalHired,
    totalCandidates: role.totalCandidates,
    candidates_to_review_per_role: role.candidates_to_review_per_role
      .filter((x) => x.id != null)
      .map(({ id, name, status, statusText }) => ({
        id: id as number,
        name,
        status,
        statusText: statusText || null,
      })),
    view_all_candidate_matches_link: role.explore_candidate_applied_filter_link,
  };
}

export function dashboard_pending_role_serializer(
  data: SelectAiCandidatesMatchingJobMutation | null | undefined,
  role: PendingJob,
): ComponentSerializerProps<typeof DashboardPendingRole> {
  return {
    currentRoleCandidateMatches: to_matching_candidates(data, role.must_have_skills),
    name: role.name || '',
    id: role.id.toString(),
    positions_count: role.positions,
    yoe: role.yoe,
    topSkills: role.topSkills,
    status: role.status,
    view_all_candidate_matches_link: role.explore_candidate_applied_filter_link,
  };
}
